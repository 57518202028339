"use client";

import { useEffect } from "react";

export default function Error({ error, reset }:{ error: Error, reset: () => void }) {
  useEffect(() => {
    console.error("Erro detectado:", error);

    // Recarregar automaticamente após 5 segundos
    const timer = setTimeout(() => {
      reset(); // Tenta reiniciar a página
    }, 5000);

    return () => clearTimeout(timer);
  }, [error, reset]);

  return (
    <div style={{ textAlign: "center", padding: "50px" }}>
      <h1>Ocorreu um erro 😞</h1>
      <p>A página será recarregada automaticamente em 5 segundos.</p>
      <p>Detalhes do erro: {error.message}</p>
      <button onClick={() => reset()} style={{ marginTop: "10px", padding: "10px 20px", fontSize: "16px" }}>
        Tentar agora
      </button>
    </div>
  );
}
